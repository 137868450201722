import React from "react"
import { graphql, navigate } from "gatsby"
import { Page } from "@web-punks/core"
import { withSanityPreview } from "@web-punks/contents-sanity"
import { HomePageQuery } from "../__generated__/HomePageQuery"

interface Props {
  data: HomePageQuery
}

const HomePage = ({ data }: Props) => {
  React.useEffect(() => {
    navigate("/horse-game")
  }, [])
  return <Page id={data.sanityHome?._id ?? undefined}>{""}</Page>
}

export default withSanityPreview(HomePage)

export const pageQuery = graphql`
  query HomePageQuery($id: String!) {
    sanityHome(_id: { eq: $id }) {
      _id
      data {
        contents {
          title
          games {
            _id
            data {
              contents {
                title
              }
            }
          }
        }
      }
    }
  }
`
